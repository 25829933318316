<template>
  <section id="Team">
    <div class="container">
      <main-heading-block class="team_content_head">
        <h2>{{ $t('home_team_content.title')}}</h2>
        <p> {{ $t('home_team_content.description')}} </p>
      </main-heading-block>
      <div class="team_content_part"  >
        <carousel :wrap-around="true" :autoplay="2000" :breakpoints="breakpoints" :pauseAutoplayOnHover="true">
          <slide class="slide"
              v-for="(member, key) in this.$i18n.messages[this.$i18n.locale].our_team_content_bloc_items" :key="key">
            <team-member-item-block :team-member="member"/>
          </slide>
        </carousel>
      </div>
      <div class="team_button">
        <router-link class="btn outline" to="/team">{{ $t('more_info')}}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import TeamMemberItemBlock from "@/components/blocks/TeamMemberItemBlock";
import Buttons from "@/components/Buttons";

export default {
  name: "TeamHome",
  components: {
    TeamMemberItemBlock,
    MainHeadingBlock,
    Buttons,
    Carousel, Slide,
  },
  data() {
    return {
      breakpoints: {
        767.98: {
          itemsToShow: 3,
        },
        991.98: {
          itemsToShow: 3,
        },
        1199.98: {
          itemsToShow: 3,
        },
        1399.98: {
          itemsToShow: 3.3,
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>

section#Team {
  background-image: url(../../assets/svg/footer.svg);
  background-color: var( --bg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}

.team_button{
  margin-top: 3rem;
  text-align: center;
}

section.carousel {
  padding:  0;
}

.team_content_part {
  position: relative;
  user-select: none;
}

.team_content_head {
  margin-bottom: 3rem;
  color: var(--color5);
}

.slide {
  align-items: start;
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .team_button {
    margin-top: 2rem;
  }
}

</style>