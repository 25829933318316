export default {
    methods: {
        handleScroll: function (selector, classname='active', heightOffset = 150, remove = true) {
            const reveals = document.querySelectorAll(selector);
            for (let i = 0; i < reveals.length; i++) {
                let elementTop = reveals[i].getBoundingClientRect().top;

                if (elementTop < window.innerHeight - heightOffset) {
                    reveals[i].classList.add(classname);
                } else if (remove) {
                    reveals[i].classList.remove(classname);
                }
            }
        },
    },
}