<template>
  <section class="about_content">
    <div class="container">
      <main-heading-block class="about_content_heading animate__animated animate__fadeInUp animate__fast">
        <h2>{{ $t('about_content_head.title') }} </h2>
        <p>{{ $t('about_content_head.description') }}</p>
      </main-heading-block>
      <div class="about_content_blocs">
        <div class="about_content_bloc" v-for="(block, key) in this.$i18n.messages[this.$i18n.locale].about_content_bloc_items" :key="`block-${key}`">
          <content-block-item
              v-for="(blocItem, i) in block" :key="i"
              :bloc-item="blocItem"
              theme="green"
              :class="['animate__animated content_block_item',  key === 0 ? 'left' : 'right']"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import ContentBlockItem from "@/components/blocks/ContentBlockItem";
import HandleScrollMixin from "@/mixins/HandleScrollMixin";

export default {
  components: {ContentBlockItem, MainHeadingBlock},
  mixins: [HandleScrollMixin],
  methods: {
    onScroll: function () {
      this.handleScroll( '.content_block_item.right', 'animate__fadeInRight', 120, false)
      this.handleScroll( '.content_block_item.left', 'animate__fadeInLeft', 120, false)
      this.handleScroll( '.about_content_heading', 'animate__fadeIn', 120, false)
    },
  },
  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>

.content_block_item {
  opacity: 0;
}

h2 {
  color: var(--color5);
}

.about_content {
  background-color: var(--bg);
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.about_content_blocs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background-image: url(../../assets/about_img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain ;
}

.about_content_bloc>div {
  margin-top: 5vmin;
}


// Large devices (desktops, less than 1200px)
@media (max-width: 1399.98px) {
  .about_content_blocs {
    background: linear-gradient(0deg, rgba(246, 242, 242, 0.35), rgba(246, 242, 242, 0.35)), url(../../assets/about_img.png) no-repeat center;
    background-size: auto 100%;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .about_content_blocs {
    background-image: linear-gradient(0deg, rgba(246, 242, 242, 0.55), rgba(246, 242, 242, 0.55)), url(../../assets/about_img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80% auto;
    flex-direction: column;
  }

  .about_content_bloc>div {
    margin-top: 5rem;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .about_content_blocs {
    background-attachment: fixed;
  }
}
</style>
