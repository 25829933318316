import portfolio from "./_portfolio";

export default {
    not_found: "Page not found",
    home_page: "Web, Mobile & Software Design, Development Company",
    about_page: "Development of diverse projects",
    contact_page: "How to contact us",
    portfolio_page: " portfolio page title",
    service_page: "Web, Mobile & Software Application Development, Design",
    team_page: "Highly qualified employees with extensive experience",
    home_content_text: "We build stunning websites and robust mobile, software applications",
    about_content_text: "L-IT help brands across the globe design and build innovative products and digital experiences for the modern world",
    contact_content_text: "You can contact us by filling out the form on the website, and we will contact you!",
    service_content_text: "Our development team have solid experience with small, medium, large and complex builds. We have built custom CRM systems, online subscription platforms, advanced booking applications and many more.",
    team_content_text: "Our team consists of qualified specialists, including project managers, web & mobile developers, software & QA engineers, designers, layout designers and SEO specialists.",
    head_button: "More",
    menu: require('./_menu.json'),
    about_content_bloc_items: [
        [
            {
                icon: require('@/assets/svg/4.svg'),
                title: 'Information analysis',
                description: 'We receive data and discuss the wishes of the client. We analyze the specifics of the company`s activities, set tasks.'
            },
            {
                icon: require('@/assets/svg/5.svg'),
                title: 'Structure design',
                description: 'We plan and organize the fundamental layout and architecture of the build, creating a clear and logical structure.'
            },
            {
                icon: require('@/assets/svg/6.svg'),
                title: 'Interface',
                description: 'We individually approach the formation of an adaptive interface, carefully work out the details and graphic elements.'
            },
        ],
        [
            {
                icon: require('@/assets/svg/7.svg'),
                title: 'Technical implementation',
                description: 'We offer technical implementation, turning the design and specifications into functional, interactive, and responsive digital products. It involves writing code, configuring servers, and integrating various technologies to bring a project to life.'
            },
            {
                icon: require("@/assets/svg/8.svg"),
                title: 'Promotion and optimization',
                description: 'We use tools, advanced strategies, and experiments to improve the performance of your website,  drive more traffic, increase conversions, and grow revenue.'
            },
            {
                icon: require('@/assets/svg/9.svg'),
                title: 'Follow-up site support',
                description: 'We accompany the projects that we have done ourselves from scratch, and others that come to us for maintenance service.'
            },
        ]
    ],
    about_content_head: {
        title: "ABOUT COMPANY",
        description: "L-IT Armenia was formed in 2021 with a motor power of providing outstaffing and outsourcing services ranging from strategic IT consultancy to solutions implementation. We are made up of bold and creative thinkers that come from every walk of life. Our open culture contributes to our clients' success and achievements, and our commitment to fostering inclusivity across the company creates significant growth opportunities for our team.",
    },
    home_service_content_heads: {
        title: 'Our services',
        description: 'We create and promote sites, mobile and software applications of various types, and engage in their subsequent support.'
    },
    service_content_blocs: [
        {
            icon: require("@/assets/svg/2.svg"),
            title: 'Software development',
            description: 'Our skillful team of developers take pride and care in every line of code to bring performant projects to life.'
        },
        {
            icon: require('@/assets/svg/3.svg'),
            title: 'API system integration',
            description: 'We can tightly integrate your existing business systems using API integrations.'
        },
        {
            icon: require('@/assets/svg/1.svg'),
            title: 'UI/UX design',
            description: 'We will create for you a personalized design, with an attractive interface, with a well-thought-out architecture and with convenience and ease of use that meets business goals.'
        },
    ],
    mission_content_heads: {
        title: 'OUR MISSION',
        description: 'Our mission is to provide optimal solutions with top quality and best services at affordable prices. In Our Business Policy Customer satisfaction is given top priority. Our team is very friendly in dealings to the customers and it helps us to retain lifelong relationship with our existing clients and expand our customer circle worldwide. We always try to improve the quality of our products by exploring innovative ideas and new techniques. We always try to enhance efficiency, productivity and capability of businesses by delivering the services above &beyond industry values and customer expectations. We allow the businesses to expand their boundaries by making innovative & impeccable business partnership with us. We visualize our organization to be the first and the last stop for the businesses across the globe seeking out for long-term success and prosperity with the help of our team.'
    },
    home_team_content: {
        title: 'Team',
        description: 'Meet the L-IT Armenia Family! Our dynamic team of creatives and innovators love working collaboratively to turn your visions into a reality.'
    },
    more_info: "More",
    info: "Info",
    info_menu: [
        {url: '/', name: 'Home'},
        {url: '/about', name: 'About'},
        {url: '/service', name: 'Service'},
        {url: '/team', name: 'Team'},
        {url: '/portfolio', name: 'Portfolio'},
        {url: '/contact', name: 'Contact'},
    ],
    contents_footer_mission: {
        title: 'OUR MISSION',
        description: 'Be a reliable digital technology partner for our clients by proactively engaging and understanding their business needs and requirements, and delivering outcomes as one cohesive team.'
    },
    dop_info: "For more information, you can subscribe to our news",
    write_email: 'write your email address',
    subscription: "Subscription",
    methods_content_bloc_items: [
        {
            icon: require('@/assets/svg/10.svg'),
            title: 'Information analysis',
            description: 'We receive data and discuss the wishes of the client. We analyze the specifics of the company`s activities, set tasks.'
        },
        {
            icon: require('@/assets/svg/11.svg'),
            title: 'Structure design',
            description: 'We plan and organize the fundamental layout and architecture of the build, creating a clear and logical structure.'
        },
        {
            icon: require('@/assets/svg/12.svg'),
            title: 'Interface',
            description: 'We individually approach the formation of an adaptive interface, carefully work out the details and graphic elements.'
        },
        {
            icon: require('@/assets/svg/13.svg'),
            title: 'Technical implementation',
            description: 'We offer technical implementation, turning the design and specifications into functional, interactive, and responsive digital products. It involves writing code, configuring servers, and integrating various technologies to bring a project to life.'
        },
        {
            icon: require('@/assets/svg/14.svg'),
            title: 'Promotion and optimization',
            description: 'We use tools, advanced strategies, and experiments to improve the performance of your website,  drive more traffic, increase conversions, and grow revenue.'
        },
        {
            icon: require('@/assets/svg/15.svg'),
            title: 'Follow-up site support',
            description: 'We accompany the projects that we have done ourselves from scratch, and others that come to us for maintenance service.'
        },

    ],
    methods_content_head: {
        title: 'OUR METHODS',
        description: 'We have a deep knowledge and proven track record in a variety of industries, including Educational, Fintech, Shipping, Booking eCommerce and many more, using various technology stacks and always staying up to date with the latest trends and advancements.'
    },
    page_content_head: {
        title: 'ABOUT COMPANY',
        description: 'L-IT Armenia was formed in 2021 with a motor power of providing outstaffing and outsourcing services ranging from strategic IT consultancy to solutions implementation. We are made up of bold and creative thinkers that come from every walk of life. ',
        description2: 'Our open culture contributes to our clients\' success and achievements, and our commitment to fostering inclusivity across the company creates significant growth opportunities for our team.'
    },
    advertising_title: {title: 'Our Services'},
    advertising_content: [
        {
            img: require('@/assets/svg/16.svg'),
            title: 'Launch efficiency',
            description: 'Your project is our priority and our specialists will fully devote their time to quickly solve the requirements of business  with high quality'
        },
        {
            img: require('@/assets/svg/17.svg'),
            title: 'Only targeted traffic',
            description: 'It is important for us not only to create the visible popularity of the resource, but to convert it into your real income'
        },
        {
            img: require('@/assets/svg/18.svg'),
            title: 'Efficiency and guarantee of results',
            description: 'We are responsible for our work and we do everything to meet your business goals'
        },
        {
            img: require('@/assets/svg/19.svg'),
            title: 'QA Testing',
            description: 'Product quality is the priority for us helping to ensure software quality control by detecting product errors and its biggest pitfalls. We test the project to the point of perfection'
        },
        {
            img: require('@/assets/svg/20.svg'),
            title: 'Development and training',
            description: 'We will show and guide you though the developed product'
        },
        {
            img: require('@/assets/svg/icon21.svg'),
            title: 'Release',
            description: 'After thorough testing and quality assurance,  we get the impeccable performance, usability and high level of security. Now ready to launch'
        }
    ],
    createds_title: {title: 'The working process', title2: 'Creating a structure for the site pages'},
    working_process_title: 'Our working process',
    working_process_letter: 'w',
    working_process: [
        {
            img: require('@/assets/svg/wp_1.svg'),
            title: 'Building a project structure',
            description: 'The site structure is the logical construction of all pages of the resource. ' +
                'The scheme by which the path to folders, categories, subcategories is distributed'
        },
        {
            img: require('@/assets/svg/wp_2.svg'),
            title: 'Design creation',
            description: 'Based on the website architecture, we prepare a design taking into account your brand book, corporate style and preferred colors'
        },
        {
            img: require('@/assets/svg/wp_3.svg'),
            title: 'Development',
            description: 'We start developing the site back to back, using different programming languages'
        },
        {
            img: require('@/assets/svg/wp_4.svg'),
            title: 'Your site is ready!',
            description: 'Adding the last elements and that`s it.',
        }
    ],
    our_team_content_head: {
        title: 'Our employees',
        description: 'Meet some members of the L-IT Armenia team!'
    },
    our_team_content_bloc_items: [
        {
            icon: require('@/assets/avatars/arman.jpg'),
            name: 'Arman',
            title: 'Chief Executive Officer',
            description: ' Result-driven and hard-working CEO skilled at pro-actively participating in all aspects of business development  and management from operations and finance to marketing and human resources. Accomplished entrepreneur capable of identifying new opportunities and sustaining extraordinary business growth.'
        },
        {
            icon: require('@/assets/avatars/hayk.jpg'),
            name: 'Hayk',
            title: 'Business Development Manager',
            description: 'Dynamic and Skilled Business Development Manager & Project Manager with the demonstrated ability to negotiate, research and communicate articulately with direct confidence. Strong desire for continuous improvement and proven ability to think strategically and act tactically, catering the needs within the scope of each project.'
        },
        {
            icon: require('@/assets/avatars/mary.jpg'),
            name: 'Mary',
            title: 'Team Lead',
            description: 'Mary possesses excellent leadership skills and a highly analytical focus. She has a strong communication style, and a solid academical and technical background. She has the ability to think outside the box, come up with innovative solutions, motivating the team and getting them work together as a unit.'
        },
        {
            icon: require('@/assets/avatars/paruyr.jpg'),
            name: 'Paruyr',
            title: 'Full-stack Developer',
            description: 'Solution-driven Web Developer adept at contributing to highly collaborative work environment, finding solutions and determining customer satisfaction. Proven experience developing consumer-focused websites.'
        },
        {
            icon: require('@/assets/avatars/noro.jpg'),
            name: 'Norayr',
            title: 'Back-End Developer',
            description: 'Self-motivation for achievement, analytical thinking and problem solving, strong interpersonal communication, curiosity for new technologies, disciplined and dedicated team player.'
        },
        {
            icon: require('@/assets/avatars/ani_p.jpg'),
            name: 'Ani',
            title: 'Software Developer',
            description: 'Software Developer proving that the dedicated team model works the best in all directions including employee interests as people have always been our main asset.'
        },
        {
            icon: require('@/assets/avatars/david.jpg'),
            name: 'David',
            title: 'WEB Developer',
            description: 'A focused and diligent PHP Developer with experience in common third-party APIs. Experienced in developing applications for a wide range of corporate, charity, and public-sector clients and having the enthusiasm to complete projects to the highest standard.'
        },

    ],
    contacts_content_head: {
        title: 'Contact us',
        description: 'If you have further questions, please feel free to contact us.'
    },
    contacts_content_bloc_items: {
        'phone': {icon: require('@/assets/svg/ph.svg'), title: 'Phone', value: '+37496433673', link: 'tel:+37496433673'},
        'email': {icon: require('@/assets/svg/em.svg'), title: 'Email', value: "info@l-it.am", link: 'mailto:info@l-it.am'},
        'address': {icon: require('@/assets/svg/ad.svg'), title: 'Address', value: 'Azatutyan 24/17, E Plaza', link: '#map'},
    },
    contact_for_footer: {
        title: 'Contacts',
        tel: '+37496433673',
        email: "info{'@'}l-it.am",
        addres: 'Azatutyan 24/17, E Plaza'
    },
    form_content_head: {
        title: 'Write your message',
        description: 'Our project manager will contact you after filling out this form'
    },
    form_inputs: {
        form_check_text: 'By submitting the form, I consent to the processing of personal data',
        submit: 'Request a Consultation',
        message: 'Message',
        phone: 'Phone',
        name: 'Full name',
        email: 'Email'
    },
    error_inputs: {
        names: 'Name is required.',
        email: 'Email is required.',
        invalid_email: 'Please enter a valid email address.',
        check: 'It is not possible to send a message without your consent.',
        phone: 'Required to specify a  Phone.',
        message: 'A message is required.',
        send_message: 'For some reason your message was not sended'
    },
    success: "Your message has been sent",
    "portfolio": portfolio
}