<template>
  <div class="main_content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MainHeadingBlock"
}
</script>

<style lang="scss" scoped>
.main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
}

.main_content:deep p {
  //font-family: var(--font_texts);
  //font-style: normal;
  //font-weight: 400;
  //text-align: center;
  color: var(--header_texts_color);
  margin-top: 2vmin;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .main_content {
    max-width: 70%;
  }

  .main_content:deep p {
    margin-top: 2rem;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .main_content {
    max-width: unset;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .main_content:deep p {
    margin-top: 1rem;
  }
}
</style>