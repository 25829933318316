<template>
  <div class="content_bloc_item">
    <div :class="['content_bloc_item_title', theme]">
      <img :src="blocItem.icon" alt="" draggable="false">
      <h3>{{ blocItem.title }}</h3>
    </div>
    <p>{{ blocItem.description }}</p>
  </div>
</template>

<script>
export default {
  name: "ContentBlockItem",
  props: {
    blocItem: {
      type: Object,
      validator: obj => {
        const missingProperties = ['icon', 'title', 'description'].filter(prop => !obj.hasOwnProperty(prop))
        return missingProperties.length === 0
      }
    },
    theme: {
      type: String,
      default: 'pink'
    }
  }
}
</script>

<style lang="scss" scoped>

.content_bloc_item_title {
  display: flex;
  align-items: start;

  img {
    width: 5vmin;
    height: 5vmin;
  }

  h3 {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color5);
    opacity: 0.98;
    margin-left: 1vmin;
  }

  &.pink h3 {
    @include underline('pink', 'left', 5vmin, 0.15vmin, 0.7vmin);
  }

  &.green h3{
    @include underline('green', 'left',  5vmin, 0.15vmin, 0.7vmin);
  }
}

p {
  font-family: var(--font_texts);
  font-style: normal;
  font-weight: 400;
  color: var(--texts_color);
  margin-top: 1rem;
  max-width: 25vw;

  // X-Large devices (large desktops, less than 1400px)
  @media (max-width: 1399.98px) {
    max-width: 32vw;
  }

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    max-width: 40vw;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 991.98px) {
    max-width: unset;
  }
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 991.98px) {
  .content_bloc_item_title {
    img {
      width: 2rem;
      height: 2rem;
    }
    h3 {
      margin-left: 0.8rem;
    }
  }
}

</style>