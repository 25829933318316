<template>
  <div class="social_media_block">
    <a v-for="mediaItem in socialMedia" :href="mediaItem.link" :class="mediaItem.name" target="_blank">
      <img :src="mediaItem.icon" draggable="false">
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  data() {
    return {
      socialMedia: [
        {
          name: 'facebook',
          icon: require('../assets/social/fb.svg'),
          link: 'https://www.facebook.com/L-IT-Armenia-100420881957627',
        },
        {
          name: 'linkedin',
          icon: require('../assets/social/ln.svg'),
          link: 'https://am.linkedin.com/company/l-it-armenia'
        },
        {
          name: 'telegram',
          icon: require('../assets/social/tg.svg'),
          link: 'https://t.me/lit_armenia'
        }
      ]
    }
  }
}
</script>

<style scoped>
.social_media_block {
  margin-top: 1.3vmin;
}

a {
  color: inherit;
  text-decoration: unset;
  display: inline-block;
  margin-left: 1.3vmin;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}
a:hover {
  transform: scale(1.2);
}

a.facebook:hover {
  filter: invert(42%) sepia(93%) saturate(300%) hue-rotate(-180deg) brightness(80%) contrast(119%);
}

a.linkedin:hover {
  filter: invert(42%) sepia(93%) saturate(300%) hue-rotate(-180deg) brightness(90%) contrast(150%);
}

a.telegram:hover {
  filter: invert(42%) sepia(93%) saturate(300%) hue-rotate(-180deg) brightness(120%) contrast(119%);
}

img {
  width: 3vmin;
  height: auto;
  opacity: 0.8;
}

/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .social_media_block {
    margin-top: 1.3rem;
  }

  a {
    margin-left: 1rem;
  }

  img {
    width: 2.2rem;
  }
}

@media (max-width: 575.98px) {
  .social_media_block {
    margin-bottom: 2rem;
  }

  a {
    margin: 0 0.5rem;
  }
}
</style>