<template>
  <custom-select :options="locales" :selected="locale"  @input="selectLang">
    <template #icon>
      <img src="../assets/svg/glob.svg" draggable="false">
    </template>
  </custom-select>
</template>

<script>
import CustomSelect from "@/components/CustomSelect";

export default {
  name: "LangSelect",
  components: {CustomSelect},
  data() {
    return {
      locales: {
        en: 'EN',
        ru: 'РУ',
        am: 'Հայ'
      },
      locale: this.$i18n.locale
    }
  },

  methods: {
    selectLang: function (option) {
      this.$i18n.locale = option;
      localStorage.setItem('lang', option);
    }
  }
}
</script>

<style scoped>
</style>