<template>
  <div class="team_member_block_item">
    <div class="content">
      <img v-bind:src="teamMember.icon" alt="" draggable="false">
      <div>
        <h4>{{ teamMember.name || 'Name Surname' }}</h4>
        <h5>{{ teamMember.title }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMemberItemBlock",
  props: {
    teamMember: {
      type: Object,
      validator: obj => {
        const missingProperties = ['icon', 'title', 'description'].filter(prop => !obj.hasOwnProperty(prop))
        return missingProperties.length === 0
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.team_member_block_item {
  div.content {
    text-align: center;
    padding: 1vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    & > * {
      margin-top: 1vmin;
    }
  }

  img {
    user-select: none;
    width: 23vmin;
    height: 23vmin;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    box-shadow:0 0 7px -3px var(--color2), 0 0 4px var(--color-head_r2);
  }

  h4 {
    color: var(--color5);
    opacity: 0.9;
    text-transform: uppercase;
    margin-top: 1.5vmin;
  }

  h5 {
    color: var(--texts_color);
    text-transform: capitalize;
    margin-top: 0.5vmin;
    white-space: pre-line;
    font-weight: 500;
  }


  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    img {
      width: 19vmin;
      height: 19vmin;
    }

    h4 {
      font-size: 0.9rem;
    }
  }


  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    img {
      width: 14rem;
      height: 14rem;
    }
    h4 {
      font-size: 1.2rem;
    }

  }
}
</style>