<template>
  <div class="custom-select" :class="{ open: open }" @blur="open = false">
    <div class="select" @click.stop="open = !open">
      <div class="icon">
        <slot name="icon"></slot>
      </div>
      <div class="label">
        <p> {{ options[value] }} </p>
      </div>
      <div class="arrow">
        <span v-if="!open"> &#x25BC;</span>
        <span v-else> &#x25B2;</span>
      </div>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(title, option) of options" :key="option"
           :class="{'selected': value === option}"
           @click="onSelect(option)">
        <p>{{ title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    selected: {
      default: null
    }
  },

  data() {
    return {
      open: false,
      value: this.selected
    };
  },

  methods: {
    onSelect: function (option) {
      if (this.value !== option) {
        this.value = option;
        this.$emit('input', option);
      }
    },
  }
};
</script>

<style lang="scss" scoped>

.icon {
  display: flex;
  align-items: center;
}

:deep(.icon) img {
  width: 2.2vmin;
  height: auto;
}

.select {
  display: flex;
  align-items: center;
}

.selected {
  background: rgba(0, 0, 0, 0.1);
  color: #9240FC;
}

.selectHide {
  display: none;
}

.arrow {
  text-align: center;
}

.custom-select {
  position: relative;
  outline: none;
  color: #1D4685;
  cursor: pointer;
  user-select: none;
  font-family: var(--font_head);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  span {
    text-align: center;
    display: inline-block;
    transform: rotateX(64deg);
    // XX-Large devices (larger desktops, 1400px and up)
    @media (min-width: 1400px) {
      font-size: 1.5vmin;
    }
  }

  .label {
    /*color: #1D4685;*/
    padding: 0 0.2vmin 0 0.5vmin;
    cursor: pointer;
    user-select: none;
    font-family: var(--font_head);
    position: relative;
  }

  .items {
    color: #1D4685;
    overflow: hidden;
    position: absolute;
    top: 2.8vmin;
    right: 5%;
    min-width: 90%;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(155, 155, 155, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3em;
    padding: 0.3vmin;

    div {
      padding: 0.3vmin;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        color: #1D4685;
      }
    }
  }
}

/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

  :deep(.icon) img {
    width: 1.4rem;
    height: auto;
  }

  .custom-select {
    .label {
      padding: 0 0.2rem 0 0.5rem;
    }

    .items {
      top: 120%;
      right: unset;
      padding: 0.5rem;

      div {
        padding: 0.3rem 0.5rem;
      }
    }
  }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  :deep(.icon) img {
    width: 1.2rem;
  }
  .select {
    align-items: center;
  }
}

</style>
