<template>
  <section>
    <div class="container">
      <main-heading-block class="animate__animated animate__fadeIn animate__slow">
        <h2>{{ $t('home_service_content_heads.title') }}</h2>
        <p> {{ $t('home_service_content_heads.description') }}</p>
      </main-heading-block>
      <div class="service_content_blocs animate__animated animate__fadeIn ">
        <div class="service_content_bloc"
             v-for="(service_content_bloc, i) in this.$i18n.messages[this.$i18n.locale].service_content_blocs"
             :key="service_content_bloc">
          <img class="service_content_icon animate__animated animate__faster"
               :style="{'animation-delay': `calc(var(--animate-delay) * 0.${++i*1.2})`}"
               v-bind:src="service_content_bloc.icon" draggable="false"/>
          <h3>{{ service_content_bloc.title }}</h3>
          <p> {{ service_content_bloc.description }} </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import HandleScrollMixin from "@/mixins/HandleScrollMixin";

export default {
  components: {MainHeadingBlock},
  mixins: [HandleScrollMixin],
  methods: {
    onScroll: function () {
      this.handleScroll('.service_content_icon', 'animate__fadeInUp', 120, false)
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.service_content_icon {
  opacity: 0;
}

.service_content_head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service_content_head > p {
  font-family: var(--font_texts);
  font-style: normal;
  text-align: center;
  color: var(--texts_color);
  margin-top: 1rem;
  max-width: 50%;
}

.service_content_blocs {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.service_content_bloc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vmin;
  text-align: center;

  h3 {
    color: var(--color5);
    opacity: 0.98;
    margin-top: 3vmin;
    @include underline('green', 'center', 3vmin, 0.2vmin, 1.5vmin)
  }

  p {
    font-size: 1.5vmin;
    max-width: 20vw;
    font-weight: 400;
    color: var(--texts_color);
    margin-top: 3vmin;
  }

  img {
    height: 18vmin;
    width: auto;
  }
}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .service_content_blocs {
    flex-direction: column;
  }

  .service_content_bloc {
    margin-top: 5rem;

    h3 {
      color: var(--color5);
      opacity: 0.98;
      margin-top: 3vmin;
      @include underline('green', 'center', 2rem, 1px, 0.5rem)
    }

    p {
      max-width: 70vw;
      font-size: 1rem;
    }

    img {
      height: 10rem;
      width: auto;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

  .service_content_bloc {
    p {
      max-width: 70vw;
      font-size: 1.2rem;
    }

    img {
      height: 14rem;
      width: auto;
    }
  }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .service_content_bloc {
    p {
      font-size: 1rem;
    }

    img {
      height: 10rem;
    }
  }
}
</style>
