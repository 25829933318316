<template>
  <header>
    <!--  Background  -->
    <div class="background">
      <div class="bg-left">
        <div class="bg-left-lft"></div>
      </div>
      <div class="bg-right"></div>
      <div class="animation">
        <slot name="animation"/>
      </div>
    </div>
    <!--  Main content  -->
    <div class="content">
      <div class="container">
        <div class="flex-wrapper">
          <div>
            <div class="header">
              <div class="logo animate__animated animate__pulse animate__infinite">
                <router-link to="/">
                  <img src="../assets/logo.png" alt="" draggable="false">
                </router-link>
              </div>
              <!--    Menu for small devices   -->
              <div id="sidemenu">
                <button class="sidemenu__btn" v-on:click="navOpen=!navOpen" :class="{active:navOpen}">
                  <span class="top"></span>
                  <span class="mid"></span>
                  <span class="bottom"></span>
                </button>
                <transition name="translateX">
                  <nav v-show="navOpen">
                    <div class="sidemenu__wrapper">
                      <Menu :page="page"/>
                      <LangSelect class="lang_select"/>
                    </div>
                  </nav>
                </transition>
              </div>
              <div class="lang">
                <LangSelect/>
              </div>
            </div>
            <div class="main">
              <div class="content_left">
                <h1 class="animate__animated animate__fadeInUp">{{ $t(head_text) }}</h1>
                <p class="animate__animated animate__fadeIn">{{ $t(content_text) }}</p>
                <div class="animate__animated animate__fadeIn">
                  <slot name="button"/>
                </div>
              </div>
              <Menu :page="page" class="burger_menu"/>
            </div>
          </div>
          <div class="line-wrapper">
            <div class="content_right_line">
              <div class="line"></div>
              <div class="line-content">
                <div>IT DEVELOPMENT</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';
import Menu from '@/components/Menu.vue';
import LangSelect from "@/components/LangSelect";

export default {
  name: "Header_.vue",
  props: {
    head_text: String,
    content_text: String,
    page: String,
  },
  components: {
    LangSelect,
    CustomSelect,
    Menu,
  },
  data() {
    return {
      navOpen: false,
    }
  },
}
</script>

<style lang="scss" scoped>

header {
  position: relative;
  margin: 0 auto;
  box-shadow: 0 0.35vmin 0.5rem rgb(0 0 0 / 11%), 0 6px 1.3rem rgb(0 0 0 / 1%) !important;
  display: flex;
  height: 47vmax;
  min-height: 45rem;
  max-height: 75vmin;
  overflow: hidden;
}

h1 {
  position: relative;
  @include underline('', '', 5vmin, 0.2vmin, 1.2vmin)
}

.container {
  display: flex;
}

.content {
  @include absoluteFullSize();
  .container {
    min-height: 100%;
  }
  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > div:first-child {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 2.5vmin 0 1vmin;

    .logo img {
      height: 7.5vmin;
      width: auto;
      margin-left: -1.8vmin
    }

    .lang {
      display: flex;
      justify-content: center;
      padding: 0.7rem 0 0;
      margin-right: -1.2rem;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    min-height: 65%;

    .content_left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      width: 35%;

      p {
        font-family: var(--font_head);
        font-weight: 300;
        font-size: 1.75vmin;
        opacity: 0.8;
        margin-top: 3vmin;
      }
    }
  }
}

.background {
  width: 100%;
  @include absoluteFullSize();
  position: static;
  display: flex;
  justify-content: space-between;

  .animation {
    @include absoluteFullSize();
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    max-width: 600px;
    margin: 0 auto;
  }
  .bg-left {
    width: 70%;
    display: flex;
    justify-content: space-between;
    transform: translateX(-30%) skew(-25deg, 0deg);
    @include blueRadialGradient();
  }
}

.line-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vmin;
  pointer-events: none;
}

.content_right_line {
  z-index: -10;
  width: 1vmin;
  height: 90%;
  margin-left: 4vmin;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_right_line .line {
  border-left: 0.1vmin solid #0000003d;
  height: 95%;
  width: 2vmin;
  margin: 15% auto ;
}

.content_right_line .line-content {
  position: absolute;
  top: 29%;
  right: 25%;
  z-index: 10;
  width: 1vmin;
  transform: rotate(90deg);
  font: 2.5vmin/0 'Averia Serif Libre';
  letter-spacing: 0.3vmin;
  white-space: nowrap;

  div {
    width: 28vmin;
    height: 2vmin;
    padding: 1vmin 1.5vmin;
    background: white;
    color : rgba(0, 0, 0, 0.73);
    text-align: center;
    opacity: 0.95;
  }
}

#sidemenu {
  display: none;
}

#sidemenu nav {
  width: 100%;
  @include blueRadialGradient();
  position: absolute;
  left: 0;
  z-index: 99;
  min-height: 80%;
  margin-top: 40px;
  padding-bottom: 2.5rem;
}

#sidemenu .sidemenu__btn {
  display: block;
  width: 50px;
  height: 50px;
  background: unset;
  border: none;
  position: relative;
  z-index: 100;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  margin-right: -0.8rem;
}

#sidemenu .sidemenu__btn span {
  display: block;
  width: 20px;
  height: 2px;
  margin: auto;
  background: white;
  @include absoluteFullSize();
  transition: all 0.5s ease;
}

#sidemenu .sidemenu__btn span.top {
  transform: translateY(-8px);
}

#sidemenu .sidemenu__btn span.bottom {
  transform: translateY(8px);
}

#sidemenu .sidemenu__btn.active .top {
  transform: rotate(-45deg);
}

#sidemenu .sidemenu__btn.active .mid {
  transform: translateX(20px);
  opacity: 0;
}

#sidemenu .sidemenu__btn.active .bottom {
  transform: rotate(45deg);
}

#sidemenu .sidemenu__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidemenu__wrapper .custom-select {
  color: white;
  margin-top: 3vmin;
}

.translateX-enter {
  transform: translateX(200px);
  opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}

.translateX-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  header {
    min-height: 600px;
  }

  .sidemenu__wrapper  .lang_select{
      position: absolute;
      top: 1%;
      right: 8%;
  }

  #sidemenu {
    display: block;
  }
  .background {
     .bg-right, .bg-left-rght {
      display: none;
    }
    .animation {
      opacity: 0.15;
      z-index: 0;
    }

    .bg-left {
      width: 100%;
      transform: unset;
    }
  }
  .content {
    .header .lang,
    .main .burger_menu,
    .line-wrapper {
      display: none;
    }

    .burger_menu {
      color: #1D4685;
    }
    .header .logo img {
      height: 4.5rem;
    }

    .main {
      //min-height: 100%;

      .content_left {
        max-width: unset;
        width: 100%;
        text-align: center;

        h1 {
          &::after {
            display: none;
          }
        }

        p {
          font-size: 1.1rem;
          line-height: 1.5rem;
          margin-top: 3rem;
          @include underline('', 'center', 65px, 2px, 10px);
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .content .main {
    padding-top: 3rem;
    .content_left p {
      font-size: 1.2rem;
      max-width: 80%;
      margin: 2rem auto;
    }
  }
  header {
    max-height: unset;
    min-height: 600px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .content {
    .header .logo img {
      height: 3.8rem;
    }
    .main .content_left {
      p {
        font-size: 3.7vmin;
        max-width: 100%;
      }
    }
  }
}

</style>