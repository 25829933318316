<template>
  <footer>
    <div class="container">
      <a class="back_to_top" ref="scroll" href="#app">
        <span>&#10148;</span>
      </a>
      <section class="contents_footer">
        <div>
          <div>
            <h5>{{ $t('contents_footer_mission.title') }}</h5>
            <p>
              {{ $t('contents_footer_mission.description') }}
            </p>
          </div>
        </div>
        <div>
          <div>
<!--            <h5 style="">{{ $t('info') }}</h5>-->
            <ul>
              <li v-for="info in this.$i18n.messages[this.$i18n.locale].info_menu" :key="info">
                <router-link :to="info.url">{{ info.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h5 style="">{{ $t('menu.contact') }} </h5>
            <p><a :href="'tel:'+$t('contact_for_footer.tel') ">{{ $t('contact_for_footer.tel') }}</a></p>
            <p><a :href="'mailto:'+$t('contact_for_footer.email')">{{ $t('contact_for_footer.email') }}</a></p>
            <p>{{ $t('contact_for_footer.addres') }}</p>
            <SocialMedia/>
          </div>
        </div>
      </section>
    </div>
    <div class="footer_bottom">
      <div>
        {{ year }} | With &#10084; by L-IT
      </div>
    </div>
  </footer>
</template>

<script>

import SocialMedia from "@/components/SocialMedia";
export default {
  name: 'Header',
  props: {},
  components: {SocialMedia},
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    trackScroll() {
      let scroll = this.$refs.scroll
      let scrolled = window.pageYOffset;
      let coords = document.documentElement.clientHeight;

      if (scroll == null)
        return;

      if (scrolled > coords) {
        scroll.classList.add('show');
      } else {
        scroll.classList.remove('show');
      }
    },
  },
  created: function () {
    window.addEventListener('scroll', this.trackScroll)
  },
};

</script>

<style scoped lang="scss">

footer {
  @include blueRadialGradient();
  color: white;

  p, a {
    font-family: var(--font_texts);
    font-style: normal;
    font-weight: 400;
    color: var(--text-footer);
    text-decoration: none;
    max-width: 20vmax;
  }

  h5 {
    //color: #565555;
    margin-bottom: 1.36vmin;
  }

  ul {
    list-style: none;

    li {
      padding: 0.15vmin 0;
    }

    & > li > a {
      text-decoration: none;
      max-width: 20vw;
      letter-spacing: 0.15em;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.contents_footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4vmin 0;
  //align-items: end;

  & > div {
    flex-grow: 1;
    width: 33%;
    display: flex;
    &:nth-child(2) {
      justify-content: center;
    }
    &:last-child {
      justify-content: end;
      text-align: right;
      p {
        margin-top: 5px;
      }
    }

    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
      &:first-child {
        width: 100%;
        margin-bottom: 2.5rem;
        text-align: center;
      }
      &:nth-child(2) {
        justify-content: start;
      }
      p{
        max-width: unset;
      }
      &:last-child {
        align-items: end;
      }
    }


    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
      width: 100%;
      &:first-child {
        width: 100%;
        margin-top: 2rem;
        //order: 2;
        h5 {
          text-align: center;
        }
      }
      &:not(:first-child) {
        width: 50%;
      }
      &:not(:last-child) {
        justify-content: start;
      }
      p, a {
        max-width: 100%;
      }
    }
    // X-Small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      width: 100% !important;
      justify-content: center !important;
      text-align: center !important;

      &:last-child {
        order: 3;
        margin-top: 2rem;
      }
    }
  }
}

.back_to_top {
  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  position: fixed;
  z-index: 9999;

  bottom: 5vmin;
  right: 2.5vmin;
  width: 4.8vmin;
  height: 4.8vmin;
  font-size: 2vmin;

  background: linear-gradient(90deg, rgba(146, 64, 252) 0%, rgba(225, 153, 252) 100%);
  border-radius: 50%;
  transform: rotate(-90deg);

  opacity: 0.35;

  &:hover {
    box-shadow: 0 0 .2em var(--color3);
  }

  &.show {
    display: flex;
  }

  span {
    line-height: 3.2vmin;
    text-align: center;
    padding-bottom: 5%;
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    width: 3rem;
    height: 3rem;
    right: 2rem;
    font-size: 1.4rem;
  }
}

.footer_bottom{
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  font-size: 0.9rem;
}

@media (max-width: 575.98px) {
  .contents_footer > div:nth-child(2) {
    display: none;
  }

  .contents_footer > div:last-child {
    margin-top: 0;
  }
}

</style>
