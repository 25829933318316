<template>
  <div :class="this.$i18n.locale">
    <slot/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';

export default {
  components: {Footer}
}
</script>

<style>

</style>