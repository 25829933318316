import portfolio from './_portfolio'
export default {
    not_found: "Էջը չի գտնվել",
    home_page: "Վեբ դիզայնի և ծրագրավորման կազմակերպություն",
    about_page: "Կայքերի Զարգացում և Հետագա Առաջխաղացում",
    contact_page: "Ինչպե՞ս Կապ Հաստատել Մեզ Հետ",
    service_page: "Վեբ կայքերի և ծրագրային ապահովման մշակում",
    team_page: "Բարձր Որակավորում Ունեցող և Փորձառու Աշխատակիցներ",
    home_content_text: "Մենք ստեղծում ենք զարմանալի կայքեր և հուսալի ծրագրային հավելվածներ",
    about_content_text: "Մեր բարձրակարգ մասնագետները կարճ ժամանակում կստեղծեն կայքեր, հավելվածներ` ըստ ձեր ճաշակի և ցանկության",
    contact_content_text: "Դուք կարող եք կապ հաստատել մեզ հետ՝ լրացնելով ներքևում գտնվող ձևաթուղթը",
    service_content_text: "Մեր ծրագրավորման թիմն ունի փոքր, միջին, մեծ և բարդ նախագծեր ստեղծելու մեծ փորձ: Մենք ստեղծել ենք հատուկ CRM համակարգեր, առցանց բաժանորդագրման հարթակներ, ամրագրման առաջադեմ հավելվածներ և շատ ավելին:",
    team_content_text: "Ծանոթացեք L-IT Armenia ընտանիքին: Ստեղծագործների և նորարարների մեր դինամիկ թիմը սիրում է աշխատել միասին՝ ձեր գաղափարները կյանքի կոչելու համար:",
    head_button: "Ավելին",
    menu: require('./_menu.json'),
    about_content_bloc_items: [
        [
            {
                icon: require('@/assets/svg/4.svg'),
                title: 'Տեղեկատվության վերլուծություն',
                description: 'Տվյալները ստանալուն պես, թիմը քննարկում է հաճախորդի ցանկությունները, վերլուծում ընկերության գործունեության առանձնահատկությունները:'
            },
            {
                icon: require('@/assets/svg/5.svg'),
                title: 'Կառուցվածքի դիզայն',
                description: 'Հիմքը դրվում է միայն այն ժամանակ, երբ մտածում ենք տեղեկատվության ներկայացման և ստացված տվյալների շուրջ՝ հաշվի առնելով վարքագծային գործոնը։'
            },
            {
                icon: require('@/assets/svg/6.svg'),
                title: 'Ինտերֆեյս',
                description: 'Անհատապես ենք մոտենում ճկուն ինտերֆեյսի ստեղծմանը, ուշադիր մշակում մանրամասները և գրաֆիկական տարրերը:'
            },
        ],
        [
            {
                icon: require('@/assets/svg/7.svg'),
                title: 'Տեխնիկական ռեալիզացիա',
                description: 'Ռեսուրսին մոտենում ենք իր մասշտաբայնությամբ և հաշվի ենք առնում օգտագործելիության ցուցանիշը:'
            },
            {
                icon: require('@/assets/svg/8.svg'),
                title: 'Առաջխաղացում և օպտիմալացում',
                description: 'Քրտնաջան աշխատում ենք որոնման խթանման, նախագծի ներքին և արտաքին օպտիմիզացման վրա:'
            },
            {
                icon: require('@/assets/svg/9.svg'),
                title: 'Կայքի հետագա աջակցություն',
                description: 'Աջակցում ենք և՛ մեր կողմից ստեղծված նախագծերին, և՛ բոլոր այն նախագծերին, որոնք ունեն դրա կարիքը:'
            },
        ]
    ],
    about_content_head: {
        title: 'Մեր մասին',
        description: 'L-IT Armenia-ն հիմնադրվել է 2021 թվականին՝ նպատակ ունենալով տրամադրել աութսթաֆինգ և աութսորսինգ ծառայություններ՝ սկսած ՏՏ ռազմավարական խորհրդատվությունից մինչև լուծումների իրականացում: Մեր բաց մշակույթի հեղինակը մեր հաճախորդներն են` իրենց հաջողություններով հանդերձ, որը ստեղծում է մեծ հնարավորություններ մեր թիմի համար:',
    },
    home_service_content_heads: {
        title: 'Մեր ծառայությունները',
        description: 'Մենք ստեղծում և զարգացնում ենք տարբեր տեսակի ծրագրային հավելվածներ, զբաղվում դրանց հետագա առաջխաղացմամբ'
    },
    service_content_blocs: [
        {
            icon: require('@/assets/svg/1.svg'),
            title: 'Կայքի մշակում',
            description: 'Մեր փորձառու ծրագրավորողների թիմը հոգատարությամբ է վերաբերվում կոդի յուրաքանչյուր տողին՝ նախագծերը արդյունավետ կյանքի կոչելու համար:'
        },
        {
            icon: require("@/assets/svg/2.svg"),
            title: 'API համակարգի ինտեգրում',
            description: 'Մենք կարող ենք ինտեգրել ձեր գործող բիզնես համակարգերը ցանկացած API համակարգի հետ:'
        },
        {
            icon: require('@/assets/svg/3.svg'),
            title: 'UI/UX կայքի դիզայն',
            description: 'Մենք ձեզ համար կստեղծենք վեբ կայքի անհատական ​​դիզայն՝ տպավորիչ ինտերֆեյսով, լավ մտածված կառուցվածքով, հարմարավետությամբ և օգտագործման հեշտությամբ, որը համապատասխանում է բիզնես նպատակներին:'
        },

    ],
    mission_content_heads: {
        title: 'ՄԵՐ ԱՌԱՔԵԼՈՒԹՅՈՒՆԸ',
        description: 'Մեր առաքելությունն է ապահովել օպտիմալ ու բարձրակարգ լուծումներ և ծառայություններ մատչելի գներով: Մեր բիզնես քաղաքականության մեջ առաջնահերթություն է տրվում հաճախորդների գոհունակությանը: Մեր թիմը դրական դեր է կատարում հաճախորդների հետ հարաբերություններում, և դա օգնում է մեզ պահպանել երկարաժամկետ հարաբերությունները մեր առկա հաճախորդների հետ և ընդլայնել մեր հաճախորդների շրջանակն ամբողջ աշխարհում: Մենք միշտ փորձում ենք բարելավել մեր արտադրանքի որակը՝ ուսումնասիրելով նորարար գաղափարներ և նոր տեխնիկա: Մենք միշտ փորձում ենք բարձրացնել բիզնես արդյունավետությունը, արտադրողականությունը և կարողությունները՝ ծառայություններ մատուցելով ոլորտի արժեքներից և հաճախորդների ակնկալիքներից վեր: Մենք թույլ ենք տալիս բիզնեսին ընդլայնել իրենց սահմանները՝ մեզ հետ ստեղծելով նորարարական և վստահելի բիզնես գործընկերություն: Մենք պատկերացնում ենք, որ մեր կազմակերպությունը կլինի առաջին և վերջին կանգառը այն բիզնեսների համար, որոնք մեր թիմի օգնությամբ փնտրում են երկարաժամկետ հաջողություն և բարգավաճում:'
    },
    home_team_content: {
        title: 'Մեր թիմը',
        description: 'Ծանոթացեք L-IT Armenia ընտանիքին: Ստեղծագործների և նորարարների մեր դինամիկ թիմը սիրում է աշխատել միասին՝ ձեր գաղափարները կյանքի կոչելու համար:'
    },
    more_info: "Ավելին",
    info: "Տեղեկատվություն",
    info_menu: [
        {url: '/', name: 'Գլխավոր'},
        {url: '/about', name: 'Մեր մասին'},
        {url: '/service', name: 'Ծառայություններ'},
        {url: '/team', name: 'Մեր թիմը'},
        {url: '/portfolio', name: 'Պորտֆոլիո'},
        {url: '/contact', name: 'Կոնտակտներ'},
    ],
    contents_footer_mission: {
        title: 'Մեր առաքելությունը',
        description: 'Լինել վստահելի թվային գործընկեր մեր հաճախորդների համար՝ ակտիվորեն ներգրավվելով և հասկանալով նրանց բիզնեսի կարիքները, և ապահովել արդյունքներ՝ հանդես գալով որպես մեկ միասնական թիմ:'
    },
    dop_info: "Լրացուցիչ տեղեկությունների համար կարող եք բաժանորդագրվել մեր նորություններին",
    write_email: 'գրեք ձեր էլփոստի հասցեն',
    subscription: "Բաժանորդագրվել",
    methods_content_bloc_items: [
        {
            icon: require('@/assets/svg/10.svg'),
            title: 'Տեղեկատվության վերլուծություն',
            description: 'Տվյալները ստանալուն պես, թիմը քննարկում է հաճախորդի ցանկությունները, վերլուծում ընկերության գործունեության առանձնահատկությունները:'
        },
        {
            icon: require('@/assets/svg/11.svg'),
            title: 'Կառուցվածքի դիզայն',
            description: 'Հիմքը դրվում է միայն այն ժամանակ, երբ մտածում ենք տեղեկատվության ներկայացման և ստացված տվյալների շուրջ՝ հաշվի առնելով վարքագծային գործոնը։'
        },
        {
            icon: require('@/assets/svg/12.svg'),
            title: 'Ինտերֆեյս',
            description: 'Անհատապես ենք մոտենում ճկուն ինտերֆեյսի ստեղծմանը, ուշադիր մշակում մանրամասները և գրաֆիկական տարրերը:'
        },
        {
            icon: require('@/assets/svg/13.svg'),
            title: 'Տեխնիկական ռեալիզացիա',
            description: 'Ռեսուրսին մոտենում ենք իր մասշտաբայնությամբ և հաշվի ենք առնում օգտագործելիության ցուցանիշը:'
        },
        {
            icon: require('@/assets/svg/14.svg'),
            title: 'Առաջխաղացում և օպտիմալացում',
            description: 'Քրտնաջան աշխատում ենք որոնման խթանման, նախագծի ներքին և արտաքին օպտիմիզացման վրա:'
        },
        {
            icon: require('@/assets/svg/15.svg'),
            title: 'Կայքի հետագա աջակցություն',
            description: 'Աջակցում ենք և՛ մեր կողմից ստեղծված նախագծերին, և՛ բոլոր այն նախագծերին, որոնք ունեն դրա կարիքը:'
        },

    ],
    methods_content_head: {
        title: 'ՎԵԲ ԶԱՐԳԱՑՈՒՄ - ՄԵՐ ՄԵԹՈԴՆԵՐԸ',
        description: 'Մենք հենց այն ընկերությունն ենք, որը զբաղվում է կայքերի ստեղծմամբ և զարգացմամբ, դրանց օպտիմալացմամբ և սպասարկմամբ:'
    },
    page_content_head: {
        title: 'Մեր մասին',
        description: 'L-IT Armenia-ն հիմնադրվել է 2021 թվականին՝ նպատակ ունենալով տրամադրել աութսթաֆինգ և աութսորսինգ ծառայություններ՝ սկսած ՏՏ ռազմավարական խորհրդատվությունից մինչև լուծումների իրականացում: Մեր բաց մշակույթի հեղինակը մեր հաճախորդներն են` իրենց հաջողություններով հանդերձ, որը ստեղծում է մեծ հնարավորություններ մեր թիմի համար:',
        description2: 'Մեր բաց մշակույթը հաջողություն է բերում մեր հաճախորդներին և մեր կենտրոնացումը ընկերության ամբողջ ընդգրկման վրա, ինչը հիանալի հնարավորություն է ստեղծում մեր թիմի համար:'
    },
    advertising_title: {title: 'Մեր ծառայությունները'},
    advertising_content: [
        {
            img: require('@/assets/svg/16.svg'),
            title: 'Գործարկման արդյունավետություն',
            description: 'Յուրաքանչյուր մասնագետ աշխատում է ոչ ավել քան 2 հաճախորդի հետ, ինչը թույլ է տալիս արագ լուծել ձեր բիզնեսի խնդիրները:'
        },
        {
            img: require('@/assets/svg/17.svg'),
            title: 'Բյուջեի ռացիոնալ օգտագործումը',
            description: 'Ձեր յուրաքանչյուր դրամ հենց այնպես չի վատնվում: Աշխատանքի արժեքը լիովին արդարացված է:'
        },
        {
            img: require('@/assets/svg/18.svg'),
            title: 'Արդյունքների երաշխավորում',
            description: 'Մենք պատասխանատու ենք մեր կատարած աշխատանքի համար և անում ենք ամեն ինչ մեր նպատակներին հասնելու համար:'
        },
        {
            img: require('@/assets/svg/19.svg'),
            title: 'Միայն թիրախային տրաֆիկ',
            description: 'Մեզ համար կարևոր է ոչ միայն ռեսուրսի տեսանելիություն ապահովելը, այլև դրա վերածումը ձեր իրական եկամտի:'
        },
        {
            img: require('@/assets/svg/20.svg'),
            title: 'Զարգացում և ուսուցում',
            description: 'Հնարավորինս հստակ կներկայացնենք ամեն ինչ մարքեթինգային գործունեության վերաբերյալ:'
        },
        {
            img: require('@/assets/svg/icon21.svg'),
            title: 'QA փորձարկում և թողարկում',
            description: 'Մենք կփորձարկենք  ձեր կայքը մինչև չհասնենք դրա անխափան գործարկմանը: Ձեր կայքի անթերի գործարկումը, օգտագործելիությունը և անվտանգությունը կապահովվեն մեր QA ծառայությունների միջոցով:'
        }
    ],
    createds_title: {title: 'Կայքի մշակում', title2: 'Կայքի էջերի համար կառուցվածքի ստեղծում'},
    working_process_title: 'Մեր Աշխատանքային Պրոցեսը',
    working_process_letter: 'Ա',
    working_process: [
        {
            img: require('@/assets/svg/wp_1.svg'),
            title: 'Ծրագրի նախագծում',
            description: 'Կայքի կառուցվածքը ռեսուրսի բոլոր էջերի տրամաբանական կառուցումն է։ Սխեման, որով բաշխվում է դեպի թղթապանակներ, կատեգորիաներ, ենթակատեգորիաներ տանող ճանապարհը:'
        },
        {
            img: require('@/assets/svg/wp_2.svg'),
            title: 'Դիզայնի ստեղծում',
            description: 'Ըստ կայքի կառուցվածքի պատրաստում ենք համապատասխան դիզայն՝ հաշվի առնելով ձեր ընկերության ֆիրմային ոճն ու նախընտրած գույները:'
        },
        {
            img: require('@/assets/svg/wp_3.svg'),
            title: 'Զարգացում',
            description: 'Մենք սկսում ենք զարգացնել կայքը սկզբից մինչև վերջ՝ օգտագործելով տարբեր ծրագրավորման լեզուներ:'
        },
        {
            img: require('@/assets/svg/wp_4.svg'),
            title: 'Ձեր կայքը պատրաստ է',
            description: 'Ավելացնում ենք վերջին շտրիխները և վերջ:',
        }
    ],
    createds_content: [
        {
            img: require('@/assets/svg/21.svg'),
            description: 'Կայքի կառուցվածքը ռեսուրսի բոլոր էջերի տրամաբանական կառուցումն է: Սխեման, որով բաշխվում է դեպի թղթապանակներ, կատեգորիաներ, ենթակատեգորիաներ տանող ճանապարհը'
        },
        {
            img: require('@/assets/svg/21.svg'),
            description: 'Ըստ կայքի կառուցվածքի պատրաստում ենք համապատասխան դիզայն՝ հաշվի առնելով ձեր ընկերության ֆիրմային ոճն ու նախընտրած գույները:'
        },
        {
            img: require('@/assets/svg/21.svg'),
            description: 'Մենք սկսում ենք զարգացնել կայքը հետևաբար՝ օգտագործելով տարբեր ծրագրավորման լեզուներ'
        },
        {
            img: require('@/assets/svg/21.svg'),
            title: 'ՔՈ ԿԱՅՔԸ ՊԱՏՐԱՍՏ Է:',
            description: 'Վերջին տարրերի ավելացում և վերջ',
        },
    ],
    our_team_content_head: {
        title: 'Մեր աշխատակիցները',
        description: 'Դիմավորե~ք L-IT Armenia թիմի անդամներից մի քանիսին'
    },
    our_team_content_bloc_items: [
        {
            icon: require('@/assets/avatars/arman.jpg'),
            name: 'Արման',
            title: 'CEO',
            description: 'Հանձնառու և ջանասեր գործադիր տնօրեն, ով ակտիվորեն մասնակցում է բիզնեսի զարգացման և կառավարման բոլոր ասպեկտներին՝ գործառնություններից և ֆինանսներից սկսած մինչև մարքեթինգ և մարդկային ռեսուրսներ: Փորձառու ձեռներեց, օժտված՝ նոր հնարավորություններ գտնելու և բիզնեսի անհավանական աճին օժանդակելու ունակությամբ:'
        },
        {
            icon: require('@/assets/avatars/hayk.jpg'),
            name: 'Հայկ',
            title: 'Business Development Manager',
            description: 'Դինամիկ և հմուտ բիզնեսի զարգացման և նախագծերի ղեկավար, օժտված վստահորեն բանակցելու, հետազոտություններ անցկացնելու և հստակ հաղորդակցվելու ունակությամբ։ Շարունակաբար կատարելագործվելու ցանկությունը, ռազմավարությունը և տակտիկապես գործելը նպաստում է յուրաքանչյուր նախագծի պահանջների կատարմանը:'
        },
        {
            icon: require('@/assets/avatars/mary.jpg'),
            name: 'Մերի',
            title: 'Team Lead',
            description: 'Թիմի ղեկավար՝ օժտված գերազանց առաջնորդական հմտություններով և բարձր վերլուծական կարողությամբ։ Հաղորդակցման գերազանց հմտությունները և բազմաբնույթ առաջադրանքների կատարման լավ կարողությունը օգնում է ՏՏ լուծումների հմուտ կատարմանն ու հանձնմանը:'
        },
        {
            icon: require('@/assets/avatars/paruyr.jpg'),
            name: 'Պարույր',
            title: 'Full-stack Developer',
            description: 'Նպատակասլաց Full Stack ծրագրավորող, ով իր ներդրումն ունի բարձր համագործակցային  միջավայրին, օգնում գտնել լուծումներ՝ դրա ապացույցն էլ հաճախորդների գոհունակությունն է:'
        },
        {
            icon: require('@/assets/avatars/noro.jpg'),
            name: 'Նորայր',
            title: 'Back-End Developer',
            description: 'Ինքնամոտիվացումը առաջնորդում է  վերլուծական մտածողությանն և խնդիրների լուծմանն, ուժեղ միջանձնային հաղորդակցության, նոր տեխնոլոգիաների նկատմամբ հետաքրքրասիրության, կարգապահ և նվիրված թիմակից լինելուն:'
        },
        {
            icon: require('@/assets/avatars/ani_p.jpg'),
            name: 'Անի',
            title: 'Software developer',
            description: 'Ծրագրային ապահովման մշակող, ով ապացուցում է, որ նվիրված թիմային մոդելը լավագույնս աշխատում է բոլոր ուղղություններով, ներառելով աշխատակիցների հետաքրքրությունները, քանի որ մարդիկ միշտ եղել են մեր գլխավոր արժեքը:'
        },
        {
            icon: require('@/assets/avatars/david.jpg'),
            name: 'Դավիթ',
            title: 'WEB Developer',
            description: 'Նվիրված և ջանասեր վեբ ծրագրավորող, որն ունի ընդհանուր երրորդ կողմի API-ների փորձ: Ունի կորպորատիվ և բարեգործական հաճախորդների համար հավելվածներ մշակելու փորձ, նախաձեռնող է ամենաբարձր մակարդակով նախագծերի հանձնման հարցում:'
        },
    ],

    contacts_content_head: {
        title: 'Կապվեք մեզ հետ',
        description: 'Լրացուցիչ հարցերի դեպքում, խնդրում ենք կապ հաստատել մեզ հետ'
    },
    contacts_content_bloc_items: {
        'phone': {
            icon: require('@/assets/svg/ph.svg'), title: 'Հեռախոսահամար', value: '+37496433673', link: 'tel:+37496433673'
        },
        'email': {
            icon: require('@/assets/svg/em.svg'),
            title: 'Էլ. ՀԱՍՑԵ',
            value: "info@l-it.am",
            link: 'mailto:info@l-it.am'
        },
        'address': {
            icon: require('@/assets/svg/ad.svg'), title: 'ՀԱՍՑԵ', value: 'Ազատության 24/17, E Plaza', link: '#map'
        }
    },
    contact_for_footer: {
        title: 'Contacts',
        tel: '+37496433673',
        email: "info{'@'}l-it.am",
        addres: 'Ազատության 24/17, E Plaza'
    },
    form_content_head: {
        title: 'Գրեք ձեր հաղորդագրությունը',
        description: 'Մեր մենեջերը կապ կհաստատի Ձեզ հետ այս ձևաթուղթը լրացնելուց հետո:'
    },
    form_inputs: {
        form_check_text: 'Ձևաթուղթը ներկայացնելով՝ ես համաձայնում եմ անձնական տվյալների մշակմանը',
        submit: 'Պատվիրել խորհրդատվություն',
        message: 'Հաղորդագրություն',
        phone: 'Հեռախոսահամար',
        name: 'Ամբողջական անուն',
        email: 'էլ․ հասցե'
    },
    error_inputs: {
        names: 'Անունը պարտադիր է',
        email: 'Требуется указать Email.',
        invalid_email: 'Խնդրում ենք մուտքագրել գործող էլեկտրոնային հասցե.',
        check: 'Առանց ձեր համաձայնության հնարավոր չէ հաղորդագրություն ուղարկել',
        phone: 'Հեռախոսահամարը պարտադիր է',
        message: 'Պահանջվում է հաղորդագրություն',
        send_message: 'Ինչ-ինչ պատճառներով ձեր հաղորդագրությունը չի հասել'
    },
    success: "Ձեր հաղորդագրությունն ուղարկված է",
    "portfolio": portfolio
}