<template>
  <section>
    <div class="container">
      <main-heading-block class="mission_content_head">
        <h2> {{ $t('mission_content_heads.title') }} </h2>
        <p> {{ $t('mission_content_heads.description') }} </p>
      </main-heading-block>
    </div>
  </section>
</template>

<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";

export default {
  components: {MainHeadingBlock}
}
</script>

<style scoped>

section {
  background-image: url("../../assets/svg/bgcity_about.svg"), linear-gradient(rgba(9, 55, 117, 1) 20%, rgb(30, 45, 65));
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.mission_content_head{
  color: var(--bg2);
}

.mission_content_head p {
  color: rgba(255, 255, 255, 0.47);
  font-weight: 400;
}

</style>