import portfolio from "@/locales/ru/_portfolio";

export default {
    not_found: "Страница не найдена",
    home_page: "Компания по Разработке Веб-дизайнa, Дизайна Мобильных Устройств и Программного Обеспечения",
    about_page: "Разработка сайтов под ключ и их последующее продвижение",
    contact_page: "Как связаться с нами",
    service_page: "Веб-разработка и разработка программного обеспечения",
    team_page: "Высококлассные сотрудники с большим опытом",
    home_content_text: "Мы создаем потрясающие веб-сайты и надежные программные приложения",
    about_content_text: "Наши квалифицированные специалисты создадут сайт за короткий срок по вашим пожеланиям, без жалоб",
    contact_content_text: "Вы можете связаться с нами, заполнив форму на сайте, и мы вам обязательно перезвоним!",
    service_content_text: "Наша команда веб-разработчиков имеет большой опыт работы с малыми, средними, большими и сложными сборками. Мы создали собственные CRM-системы, платформы онлайн-подписки, расширенные приложения для бронирования и многое другое.",
    team_content_text: "Наша команда состоит из квалифицированных специалистов, в том числе руководителей проектов, веб-разработчиков, мобильных разработчиков, инженеров-программистов, верстальщиков, дизайнеров и SEO-специалистов.",
    head_button: "Больше",
    menu: require('./_menu.json'),
    about_content_bloc_items: [
        [
            {
                icon: require('@/assets/svg/4.svg'),
                title: 'Анализ информации',
                description: 'Получаем данные и обсуждаем пожелания клиента. Анализируем специфику деятельности компании, ставим задачи.'
            },
            {
                icon: require('@/assets/svg/5.svg'),
                title: 'Проектирование структуры',
                description: 'Продумываем представление информации, вывод данных, учитывая поведенческий фактор. Закладываем конверсионные блоки.'
            },
            {
                icon: require('@/assets/svg/6.svg'),
                title: 'Интерфейс',
                description: 'Индивидуально подходим к формированию адаптивного интерфейса, Тщательно прорабатываем детали и графические элементы.'
            },

        ],
        [
            {
                icon: require('@/assets/svg/7.svg'),
                title: 'Техническая реализация',
                description: 'Подходим к ресурсу с точки зрения его масштабируемости. Учитываем показатель юзабилити, т.е. удобство пользования.'
            },
            {
                icon: require('@/assets/svg/8.svg'),
                title: 'Продвижение и оптимизация',
                description: 'Проводим плотную работу по поисковому продвижению. Внутренняя и внешняя оптимизация проекта.'
            },
            {
                icon: require('@/assets/svg/9.svg'),
                title: 'Последующая поддержка сайта',
                description: 'Сопровождаем как те проекты, которые сами сделали, так и любые другие, которые пришли к нам на обслуживание.'
            },

        ]
    ],
    about_content_head: {
        title: 'О КОМПАНИИ',
        description: 'L-IT Armenia была создана в 2021 году с целью предоставления услуг аутстаффинга и аутсорсинга, начиная от стратегического консультирования в области ИТ и заканчивая внедрением решений. Наша команда состоит из смелых и творческих мыслителей из разных слоев общества. Наша открытая культура способствует успеху наших клиентов, а наша ориентация на вовлечение всей компании открывает большие возможности для нашей команды.',
    },
    home_service_content_heads: {
        title: 'Наши услуги',
        description: 'Создаем и продвигаем сайты программных приложений различных типов, занимаемся их последующей поддержкой'
    },
    service_content_blocs: [
        {
            icon: require('@/assets/svg/1.svg'),
            title: 'Создание сайтов',
            description: 'Наша опытная команда разработчиков с гордостью и заботой относится к каждой строке кода, чтобы воплотить в жизнь эффективные проекты.'
        },
        {
            icon: require("@/assets/svg/2.svg"),
            title: 'Интеграция системы API',
            description: 'Мы можем тесно интегрировать ваши существующие бизнес-системы с помощью интеграции API.'
        },
        {
            icon: require('@/assets/svg/3.svg'),
            title: 'UI/UX дизайн сайта',
            description: 'Создадим для вас индивидуальный дизайн сайта, с привлекательным интерфейсом, с продуманной архитектурой сайта и с удобством и простотой использования, отвечающей целям бизнеса.'
        },
    ],
    mission_content_heads: {
        title: 'НАША МИССИЯ',
        description: 'Наша миссия состоит в том, чтобы предоставить оптимальные решения с высоким качеством и лучшими услугами по доступным ценам. В нашей бизнес-политике наивысшим приоритетом является удовлетворение потребностей клиентов. Наша команда очень дружелюбна в отношениях с заказчиками, и это помогает нам поддерживать пожизненные отношения с нашими существующими клиентами и расширять круг наших сотрудников по всему миру. Мы всегда стараемся улучшить качество нашей продукции, исследуя инновационные идеи и новые технологии. Мы всегда стараемся повысить эффективность, производительность и возможности бизнеса, предоставляя услуги выше отраслевых ценностей и ожиданий клиентов. Мы позволяем предприятиям расширять свои границы, создавая инновационное и безупречное деловое партнерство с нами. Мы представляем нашу организацию как первую и последнюю остановку для предприятий по всему миру, стремящихся к долгосрочному успеху и процветанию с помощью нашей команды.'
    },
    home_team_content: {
        title: 'Команда',
        description: 'Знакомьтесь с семьей Л-ИТ Армения! Наша динамичная команда креативщиков и новаторов любит работать вместе, чтобы воплотить ваши идеи в реальность.'
    },
    more_info: "Больше",
    info: "Информация",
    info_menu: [
        {url: '/', name: 'Главная'},
        {url: '/about', name: 'О Нас'},
        {url: '/service', name: 'Услуги'},
        {url: '/team', name: 'Команда'},
        {url: '/portfolio', name: 'Портфолио'},
        {url: '/contact', name: 'Контакты'},
    ],
    contents_footer_mission: {
        title: 'Наша миссия',
        description: 'Быть надежным партнером по цифровым технологиям для наших клиентов, активно взаимодействуя и понимая их бизнес-потребности, и добиваясь результатов как одна сплоченная команда.'
    },
    dop_info: "Для дополнительной информации вы можете подписаться на наши новости",
    write_email: 'напишите свой адрес электронной почты',
    subscription: "Подписаться",
    methods_content_bloc_items: [
        {
            icon: require('@/assets/svg/10.svg'),
            title: 'Анализ информации',
            description: 'Получаем данные и обсуждаем пожелания клиента. Анализируем специфику деятельности компании, ставим задачи.'
        },
        {
            icon: require('@/assets/svg/11.svg'),
            title: 'Проектирование структуры',
            description: 'Продумываем представление информации, вывод данных, учитывая поведенческий фактор. Закладываем конверсионные блоки.'
        },
        {
            icon: require('@/assets/svg/12.svg'),
            title: 'Интерфейс',
            description: 'Индивидуально подходим к формированию адаптивного интерфейса, Тщательно прорабатываем детали и графические элементы.'
        },
        {
            icon: require('@/assets/svg/13.svg'),
            title: 'Техническая реализация',
            description: 'Подходим к ресурсу с точки зрения его масштабируемости. Учитываем показатель юзабилити, т.е. удобство пользования.'
        },
        {
            icon: require('@/assets/svg/14.svg'),
            title: 'Продвижение и оптимизация',
            description: 'Проводим плотную работу по поисковому продвижению. Внутренняя и внешняя оптимизация проекта.'
        },
        {
            icon: require('@/assets/svg/15.svg'),
            title: 'Последующая поддержка сайта',
            description: 'Сопровождаем как те проекты, которые сами сделали, так и любые другие, которые пришли к нам на обслуживание.'
        },

    ],
    methods_content_head: {
        title: 'ВЕБ РАЗРАБОТКА - НАШИ МЕТОДЫ',
        description: 'Мы компания, занимающаяся созданием и развитием сайтов, их оптимизацией и обслуживанием.'
    },
    page_content_head: {
        title: 'О КОМПАНИИ',
        description: 'L-IT Armenia была создана в 2021 году с целью предоставления услуг аутстаффинга и аутсорсинга, начиная от стратегического консультирования в области ИТ и заканчивая внедрением решений. Наша команда состоит из смелых и творческих мыслителей из разных слоев общества.',
        description2: 'Наша открытая культура способствует успеху наших клиентов, а наша ориентация на вовлечение всей компании открывает большие возможности для нашей команды.'
    },
    advertising_title: {title: 'Наши услуги'},
    advertising_content: [
        {
            img: require('@/assets/svg/16.svg'),
            title: 'Оперативность запуска',
            description: 'Один специалист ведет не более 2 клиентов одновременно — это позволяет оперативно решать задачи вашего бизнеса.'
        },
        {
            img: require('@/assets/svg/17.svg'),
            title: 'Рациональность использования бюджета',
            description: 'Мы не расходуем ни одного рубля впустую. Стоимость работ полностью оправдана!'
        },
        {
            img: require('@/assets/svg/18.svg'),
            title: 'Эффективность и гарантия результата',
            description: 'Мы отвечаем за свою работу и делаем все, чтобы достичь поставленных целей.'
        },
        {
            img: require('@/assets/svg/19.svg'),
            title: 'Только целевой трафик',
            description: 'Нам важно не просто создать видимую популярность ресурса, а конвертировать ее в ваш реальный доход.'
        },
        {
            img: require('@/assets/svg/20.svg'),
            title: 'Развитие и обучение',
            description: 'Мы максимально доходчиво расскажем и покажем все про маркетинговые активности.'
        },
        {
            img: require('@/assets/svg/icon21.svg'),
            title: 'QA-тестирование и выпуск',
            description: 'Мы протестируем ваш сайт до совершенства. Вы получите безупречную производительность, удобство использования и безопасность вашего веб-сайта благодаря нашим высококачественным услугам QA.'
        }
    ],
    createds_title: {title: 'Рабочий процесс', title2: 'Создаем структуру для страниц сайта'},
    working_process_title: 'Наш Рабочий Процесс',
    working_process_letter: 'P',
    working_process: [
        {
            img: require('@/assets/svg/wp_1.svg'),
            title: 'Построение структуры проекта',
            description: 'Структура сайта – это логическое построение всех страниц ресурса. Схема, по которой распределяется путь к папкам, категориям, подкатегориям'
        },
        {
            img: require('@/assets/svg/wp_2.svg'),
            title: 'Создание дизайна',
            description: 'На основе архитектуры сайта подготавливаем дизайн с учетом вашего фирменного стиля и предпочитаемых цветов'
        },
        {
            img: require('@/assets/svg/wp_3.svg'),
            title: 'Разработка',
            description: 'Начинаем разработку сайта встык, используя разные языки программирования.'
        },
        {
            img: require('@/assets/svg/wp_4.svg'),
            title: 'Ваш сайт готов!!',
            description: 'Добавляем последние элементы и все.',
        }
    ],
    createds_content: [
        {
            img: require('@/assets/svg/21.svg'),
            description: 'Структура сайта - это логическое построение всех страниц ресурса. Схема, по которой распределяется путь к папкам, категориям, подкатегориям.'
        },
        {
            img: require('@/assets/svg/21.svg'),
            description: 'На основе архитектуры сайта подготавливаем дизайн с учетом вашего брендбука, фирменной стилистики и предпочитаемых цветов.'
        },
        {
            img: require('@/assets/svg/21.svg'),
            description: 'Начинаем разработку сайта вплотную, используя разные языки программирования.'
        },
        {
            img: require('@/assets/svg/21.svg'),
            title: 'ВАШ САЙТ ГОТОВ!',
            description: 'Добавление последних элементов и все.'
        }
    ],
    our_team_content_head: {
        title: 'Наши сотрудники ',
        description: 'Познакомьтесь с некоторыми членами команды L-IT Armenia!'
    },
    our_team_content_bloc_items: [
        {
            icon: require('@/assets/avatars/arman.jpg'),
            name: 'Арман',
            title: 'Директор компании',
            description: 'Ориентированный на результат и трудолюбивый генеральный директор, способный активно участвовать во всех аспектах развития бизнеса и управления от операций и финансов до маркетинга и управления персоналом. Опытный предприниматель, способный находить новые возможности и поддерживать невероятный рост бизнеса.'
        },
        {
            icon: require('@/assets/avatars/hayk.jpg'),
            name: 'Айк',
            title: 'Менеджер по развитию бизнеса',
            description: 'Динамичный и квалифицированный менеджер по развитию бизнеса и менеджер проектов с выраженной способностью вести переговоры, исследовать и четко общаться с уверенностью. Сильное стремление к постоянному совершенствованию и способность мыслить стратегически и действовать тактически, удовлетворяя потребности в рамках каждого проекта.'
        },
        {
            icon: require('@/assets/avatars/mary.jpg'),
            name: 'Мэри',
            title: 'Лидер команды',
            description: 'Руководительница ИТ-команды обладает отличными лидерскими качествами и высокой аналитической направленностью. Хорошие коммуникативные навыки и хорошая способность к многозадачности позволяют квалифицированно предоставлять ИТ-решения.'
        },
        {
            icon: require('@/assets/avatars/paruyr.jpg'),
            name: 'Паруйр',
            title: 'Full-stack разработчик',
            description: 'Веб-разработчик, ориентированный на решения, способный вносить свой вклад в рабочую среду с высокой степенью совместной работы, находить решения и определять степень удовлетворенности клиентов. Подтвержденный опыт разработки сайтов, ориентированных на потребителя'
        },
        {
            icon: require('@/assets/avatars/noro.jpg'),
            name: 'Норайр',
            title: 'Back-End разработчик',
            description: 'Самомотивация к достижениям, аналитическое мышление и решение проблем, сильное межличностное общение, любопытство к новым технологиям, дисциплинированный и преданный командный игрок.'
        },
        {
            icon: require('@/assets/avatars/ani_p.jpg'),
            name: 'Ани',
            title: 'Разработчик программного обеспечения',
            description: 'Разработчик PHP, доказывающий, что модель выделенной команды работает лучше всего во всех направлениях, включая интересы сотрудников, поскольку люди всегда были нашим главным активом.'
        },
        {
            icon: require('@/assets/avatars/david.jpg'),
            name: 'Давид',
            title: 'WEB Разработчик',
            description: 'Целеустремленный и старательный PHP-разработчик с опытом работы с распространенными сторонними API. Обладает опытом разработки приложений для широкого круга корпоративных, благотворительных и государственных организаций и энтузиазмом в выполнении проектов на самом высоком уровне.'
        },
    ],
    contacts_content_head: {
        title: 'Свяжитесь с нами',
        description: 'Если у вас есть дополнительные вопросы, пожалуйста, не стесняйтесь обращаться к нам.'
    },
    contacts_content_bloc_items: {
        'phone': {icon: require('@/assets/svg/ph.svg'), title: 'Телефон', value: '+37496433673',link: 'tel:+37496433673'},
        'email': {icon: require('@/assets/svg/em.svg'), title: 'Эл. Почта', value: "info@l-it.am", link: 'mailto:info@l-it.am'},
        'address': {icon: require('@/assets/svg/ad.svg'), title: 'Адрес', value: 'Азатутян 24/17, E Plaza', link: '#map'},
    },
    contact_for_footer: {
        title: 'Contacts',
        tel: '+37496433673',
        email: "info{'@'}l-it.am",
        addres: 'Азатутян 24/17, E Plaza'
    },
    form_content_head: {
        title: 'Напишите ваше сообщение',
        description: 'Наш проект менеджер свяжется с вами после заполнения данной формы'
    },
    form_inputs: {
        form_check_text: 'Отправляя форму, я даю согласие на обработку персональных данных',
        submit: 'Запросить Консультацию',
        message: 'Сообщение',
        phone: 'Телефон',
        name: 'ФИО',
        email: 'Эл. Почта'
    },
    error_inputs: {
        names: 'Требуется указать ФИО',
        email: 'Требуется указать Email.',
        invalid_email: 'Укажите корректный адрес электронной почты.',
        check: 'Без вашего согласие невозможно отправить сообщение.',
        phone: 'Требуется указать Телефон.',
        message: 'Требуется указать Сообщение',
        send_message: 'По каким то причинам ваше сообщения не доставлено'
    },
    success: "Ваше сообщение было отправлено",
    "portfolio": portfolio
}