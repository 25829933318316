export default {
    header_title: 'Մենք կերտում ենք ՏՏ',
    header_text: 'Մենք հավաքագրել ենք L-IT-ի կողմից ստեղծված կայքերի մի քանի աներևակայելի օրինակներ: Ոգեշնչվե՜ք և սկսե՜ք պլանավորել ձեր իդեալական ՏՏ լուծումները մեզ հետ:',
    portfolio_page_title: 'Ձեր Բիզնես Աճը Մեզ Հետ',
    portfolio_page_text: 'L-IT Armenia-ն փորձագետների թիմ է, ովքեր ստեղծում են մրցունակ, պահանջարկ ունեցող լուծումներ: Մենք իրազեկ ենք այն ոլորտներից, որոնց համար ստեղծում ենք լուծումներ կամ հարթակներ: \n' +
        'Մեր ինժեներների թիմը պատրաստ է քրտնաջան աշխատել, ձեր նպատակները հասկանալու և համապատասխան հարթակ ստեղծելու համար, որն իր հերթին կնպաստի Ձեր բիզնեսի աճին:',
    projects: [
        {
            img: [
                require('@/assets/portfolio/project1/1.jpg'),
                require('@/assets/portfolio/project1/2.jpg'),
                require('@/assets/portfolio/project1/3.jpg')
            ],
            title: 'Chatlio',
            description: 'Chatlio-ն հավելված է, որը թույլ է տալիս շփվել ձեր կայքի այցելուների և օգտատերերի հետ անմիջապես Slack-ից:'
        },
        {
            img: [
                require('@/assets/portfolio/project2/1.jpg'),
                require('@/assets/portfolio/project2/2.jpg'),

            ],
            title: 'Connecteam',
            description: 'Connecteam-ը աշխատակիցների կառավարման հավելված է, որը կապում է այն ամենը, ինչ անհրաժեշտ է բիզնեսին դաշտից գրասենյակ՝ բոլորը մեկ տեղում: Այն օգնում է մեծ ձեռնարկություններին, փոքր բիզնեսներին, խանութներին իրենց ամենօրյա գործունեությունը կառավարելու և իրենց բիզնեսն առաջ մղելու հարցում:'
        },
        {
            img: [
                require('@/assets/portfolio/project3/1.jpg'),
                require('@/assets/portfolio/project3/2.jpg'),
            ],
            title: 'NotaryAssist',
            description: 'Այն օգնում է հետևել հաճախորդներին, եկամուտները, ծախսերն, փաստաթղթաշրջանառությանն և այլն: Լուծումը հետևում է նոտարական հատուկ տվյալների կետերին և հարկային տեղեկատվությանը՝ օգնելով պրոֆեսիոնալ նոտարներին առավելագույնի հասցնել իրենց եկամուտը:'
        },
        {
            img: [
                require('@/assets/portfolio/project4/1.png'),
                require('@/assets/portfolio/project4/2.png'),
            ],
            title: 'SwipedOn',
            description: 'Ամենահեշտ ճկուն համակարգը՝ այցելուներին, անձնակազմին, առաքումները կառավարելու համար:'
        },
    ]
}