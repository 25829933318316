<template>
  <div class="menus">
    <ul>
      <li v-for="(pageItem, i) in pages" :key="i"
          :class="[{ 'isset_page' : page === pageItem.page}, 'animate__animated animate__fadeInRight']"
          :style="{'animation-delay': `calc(var(--animate-delay) * 0.${i})`}"
      >
        <router-link :to="`${pageItem.route}`">{{this.$i18n.messages[this.$i18n.locale].menu[pageItem.slug] }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    page: String,
  },
  data() {
    return {
      pages: [
        {
          slug: 'home',
          page: "home_page",
          route: '/'
        },
        {
          slug: 'about',
          page: "about_page",
          route: '/about'
        },
        {
          slug: 'service',
          page: "service_page",
          route: '/service'
        },
        {
          slug: 'team',
          page: "team_page",
          route: '/team'
        },
        {
          slug: 'portfolio',
          page: "portfolio_page",
          route: '/portfolio'
        },
        {
          slug: 'contact',
          page: "contact_page",
          route: '/contact'
        }
      ]
    }
  },

};
</script>

<style lang="scss" scoped>

.menus  {
  display: flex;
  align-items: center;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: end;
    max-width: 107px;

    li {
      font-family: var(--font_head);
      font-weight: 600;

      cursor: pointer;
      position: relative;
      margin: 1.6vmin 0;

      &.isset_page {
        @include underline('pink', 'right', 2.5vmin, 0.2vmin, 0.65vmin);
      }

      a {
        font-size: 2.25vmin;
        color: var(--color5);
        text-decoration: none;
        opacity: 0.90;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .menus ul {
    align-items: center;
    margin-top: 3rem;

    li {
      margin: 0.8rem 0;
      font-family: var(--font_head);
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2rem;
      color: var(--color5);

      &.isset_page {
        @include underline('pink', 'right', 2.5rem, 0.15rem, 0.3rem);
      }

      a {
        color: white;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 575.98px) {
  .menus ul {
    margin-top: 1.5rem;
    li {
      margin-top: 1.4rem;
      a {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }
}
</style>
