<template>
  <layout class="home">
    <Header head_text="home_page" content_text="home_content_text" page="home_page">
      <template v-slot:animation>
        <Animation/>
      </template>
      <template v-slot:button>
        <a class="btn" href="/about"> {{ $t('head_button') }} </a>
      </template>
    </Header>
    <ServiceHome/>
    <AboutHome/>
    <Mission/>
    <Team/>
  </layout>
</template>

<script>
import Header from '@/components/Header_.vue'
import Animation from '@/components/home_component/Animation.vue';
import ServiceHome from '@/components/home_component/Service_home.vue';
import AboutHome from '@/components/home_component/About_home.vue';
import Mission from '@/components/home_component/Mission.vue';
import Team from '@/components/home_component/Team_home.vue';
import Buttons from "@/components/Buttons";
import Layout from "@/layouts/Layout";

export default {
  name: 'Home',
  components: {
    Layout,
    Buttons,
    Header,
    Animation,
    ServiceHome,
    AboutHome,
    Mission,
    Team
  },
  data() {
    return {}
  }
}
</script>
<style scoped>
    .btn {
      display: inline-block;
    }
</style>
